<template>
  <div class="comment">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="评价状态">
        <el-select v-model="formInline.status" placeholder="评价状态">
          <el-option label="全部" value="0"></el-option>
          <el-option label="待审核" value="1"></el-option>
          <el-option label="显示中" value="2"></el-option>
          <el-option label="已屏蔽" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单号">
        <el-input v-model="formInline.tradeno" placeholder="输入订单号"></el-input>
      </el-form-item>
      <el-form-item label="评价时间">
        <el-date-picker v-model="formInline.datetime" value-format="yyyy-MM-dd HH:mm:sS" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%" border size="mini">
      <el-table-column prop="orderNo" label="订单号" width="140"></el-table-column>
      <el-table-column prop="title" label="所评样品"></el-table-column>
      <el-table-column prop="nickname" label="评价人" width="100"></el-table-column>
      <el-table-column prop="corporation" label="所属企业"></el-table-column>
      <el-table-column prop="create_time" label="评价时间" width="150"></el-table-column>
      <el-table-column prop="score" label="打分" width="150">
        <template slot-scope="scope">
          <el-rate v-model="scope.row.score" disabled></el-rate>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="评价"></el-table-column>
      <el-table-column prop="images" label="晒图" width="300">
        <template slot-scope="scope">
          <el-image v-for="image in scope.row.images" :key="image.index" style="width: 43px; height: 43px; margin:1px;" title="点击查看大图" :src="image" fit="contain" :preview-src-list="scope.row.images"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="评价状态" width="105">
        <template slot-scope="scope">
            <el-select v-model="scope.row.status" size="mini" @change = "changeStatus($event,scope.row.id)">
                <el-option label="待审核" :value="1"> </el-option>
                <el-option label="显示中" :value="2"> </el-option>
                <el-option label="屏蔽中" :value="3"> </el-option>
            </el-select>
        </template>
      </el-table-column>
      <el-table-column label="订单详情" width="80" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" plain size="mini" @click="handleDetail(scope.row.order_id)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev, pager, next, total" :page-size = 10 @current-change = "handle_page_current" :current-page.sync="page" :total="count"></el-pagination>
<el-dialog title="订单详情" :visible.sync="detailDialogVisible">
      <el-row>
          <el-col :span="18">
            <el-row class="detai_item">
              <el-col :span="24">样品标题：{{detail.title}}</el-col>
            </el-row>
            <el-row class="detai_item">
              <el-col :span="12">样品ID： {{detail.gid}}</el-col>
              <el-col :span="12">订单状态：
                <el-tag size="small" type="warning" v-if="detail.status == 1">待发货</el-tag>
                <el-tag size="small" v-if="detail.status == 2">已发货</el-tag>
                <el-tag size="small" type="success" v-if="detail.status == 3">已收货</el-tag>
                <el-tag size="small" type="info" v-if="detail.status == 4">已取消</el-tag>
              </el-col>
            </el-row>
            <el-row class="detai_item">
              <el-col :span="12">采购单位： {{detail.corporation}}</el-col>
              <el-col :span="12">采购人：{{detail.nickname}}</el-col>
            </el-row>
            <el-row class="detai_item">
              <el-col :span="12">订单编号：{{detail.order_id}}</el-col>
              <el-col :span="12">联系电话：{{detail.tel}}</el-col>
            </el-row>
            <el-row class="detai_item">
              <el-col :span="12">收货地址：{{detail.address}}</el-col>
              <el-col :span="12">收货人：{{detail.name}}</el-col>
            </el-row>
            <el-row class="detai_item">
              <el-col :span="12">快递单号：{{detail.express_number}}</el-col>
              <el-col :span="12">快递公司：{{detail.express}}</el-col>
            </el-row>
            <el-row>
              <el-col>备注： {{detail.mark}}</el-col>
            </el-row>
          </el-col>
        <el-col :span="6">
          <el-timeline>
            <el-timeline-item :timestamp="detail.create_time">下单时间</el-timeline-item>
            <el-timeline-item :timestamp="detail.send_time">发货时间</el-timeline-item>
            <el-timeline-item :timestamp="detail.receipt_time">收货时间</el-timeline-item>
            <el-timeline-item :timestamp="detail.comment_time">评价时间</el-timeline-item>
          </el-timeline>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="detailDialogVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Comment',
  data () {
    return {
      page: 1,
      count: 0,
      formInline: {
        tradeno: '',
        status: '0',
        datetime: []
      },
      tableData: [],
      detail: {},
      detailDialogVisible: false
    }
  },
  methods: {
    handleDetail (id) {
      this.getDetail(id)
      this.detailDialogVisible = true
    },
    handle_page_current (e) {
      this.getData(e)
    },
    onSubmit () {
      this.page = 1
      this.getData(1)
    },
    getData (e) {
      var begin = 0
      var end = 0
      var that = this
      if (this.formInline.datetime !== null && this.formInline.datetime.length === 2) {
        begin = this.formInline.datetime[0]
        end = this.formInline.datetime[1]
      }
      var data = { page: e, status: this.formInline.status, order_id: this.formInline.tradeno, begin: begin, end: end }
      this.axios.get('/admin/comment', {
        params: data
      })
        .then(function (response) {
          if (response.data.code === 0) {
            that.tableData = response.data.list
            that.count = response.data.count
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getDetail (id) {
      console.log(id)
      var that = this
      that.detail = {}
      this.axios.get('/admin/order/one?id=' + id)
        .then(function (response) {
          if (response.data.code === 0) {
            console.log('成功收到数据')
            that.detail = response.data.detail
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    changeStatus (e, i) {
      console.log(e)
      console.log(i)
      var that = this
      this.axios.post('/admin/comment/status', { id: i, status: e })
        .then(function (response) {
          if (response.data.code === 0) {
            that.$message.success('更新成功')
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  mounted () {
    this.getData(this.page)
  }
}
</script>

<style scoped>
.detai_item{
  margin-bottom: 20px;
}
</style>
